import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import update from 'immutability-helper';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndContainer from 'components/atoms/Dnd/Container';
import DndItem from 'components/atoms/Dnd/Item';
import FormQuestionRadio from 'components/molecules/Form/FormQuestion/Radio';
import FormQuestionCheckbox from 'components/molecules/Form/FormQuestion/Checkbox';
import FormQuestionFile from 'components/molecules/Form/FormQuestion/File';
import FormQuestionPlain from 'components/molecules/Form/FormQuestion/Question';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'highlight.js/styles/dark.css';
import 'react-quill/dist/quill.snow.css';
import { useEvent } from 'datas/event/store';
import { FormQuestionType, TabKey } from 'datas/event/type';
import { questionTypeRef } from "constants/event";
import './style.scss';

const formQuestionComponents = {
    [FormQuestionType.RADIO]: FormQuestionRadio,
    [FormQuestionType.CHECKBOX]: FormQuestionCheckbox,
    [FormQuestionType.FILE]: FormQuestionFile,
    [FormQuestionType.QUESTION]: FormQuestionPlain
};

export const FormEventQuestion = ():JSX.Element=> {
    const { dataQuestion, setDataQuestion, setActiveTab } = useEvent();

    const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
    const [validated, setValidated] = useState(false);
    const [questionType, setQuestionType] = useState('');

    const onPrevPage = () => {
        setActiveTab(TabKey.requirement);
    }

    const onNextPage = () => {
        setActiveTab(TabKey.ministry_types);
    }

    const onChangeQuestion = useCallback((index: number, value: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            newQuestion.splice(index, 1, {
                ...prev[index],
                question: value
            });
            return newQuestion;
        });
    }, [dataQuestion])

    const onChangeFileProperty = useCallback((index: number, key: 'fileCategory' | 'fileType' | 'fileSize', value: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            newQuestion.splice(index, 1, {
                ...prev[index],
                [key]: value
            });
            return newQuestion;
        });
    }, [dataQuestion])

    const onChangeOption = useCallback((index: number, optionIndex: number, optionValue?: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            const newOptions = [...(newQuestion[index]?.choices ?? [])];

            if (optionValue !== undefined) {
                newOptions[optionIndex] = optionValue;
            } else {
                newOptions.splice(optionIndex, 1);
            }

            newQuestion[index] = {
                ...newQuestion[index],
                choices: newOptions
            };
            
            return newQuestion;
        });
    }, [setDataQuestion]);

    const onMoveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setDataQuestion(prev =>
            update(prev, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prev[dragIndex]],
                ],
            })
        );
    }, []);

    const onCreateNewQuestion = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newDataQuestion = [...dataQuestion];
        switch (questionType) {
            case FormQuestionType.RADIO:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.RADIO,
                    question: '',
                    choices: ['']
                });
                break;
            case FormQuestionType.CHECKBOX:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.CHECKBOX,
                    question: '',
                    choices: ['']
                });
                break;
            case FormQuestionType.FILE:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.FILE,
                    question: '',
                    fileCategory: '',
                    fileType: '',
                    fileSize: ''
                });
                break;
            default:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.QUESTION,
                    question: ''
                });
                break;
        }
        setDataQuestion(newDataQuestion);
        setIsEmptyQuestion(false);
    }

    const onDuplicateQuestion = (index: number) => {
        const newDataQuestion = [...dataQuestion];
        const selectedQuestion = newDataQuestion[index];
        newDataQuestion.splice(index + 1, 0, { ...selectedQuestion, id: newDataQuestion.length });
        setDataQuestion(newDataQuestion);
    }
    
    const onDeleteQuestion = (index: number) => {
        const newDataQuestion = [...dataQuestion];
        newDataQuestion.splice(index, 1);
        setDataQuestion(newDataQuestion);
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            if (!dataQuestion?.length) {
                setIsEmptyQuestion(true);
                return;
            }

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                setValidated(true);
                return;
            }

            onNextPage();
        } catch (err: any) {

        } finally {
            // setValidated(false);
        }
    }

    useEffect(() => {
        return () => {
            setIsEmptyQuestion(false);
            setValidated(false);
            setQuestionType('');
        }
    }, [])

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='form_event_question'>
                <div className='form_event_question_header'>
                    <h2>Form Question</h2>
                </div>
                <div className='form_event_question_content'>
                    <Form onSubmit={onCreateNewQuestion}>
                        <div className='d-flex gap-1'>
                            <Form.Select
                                required
                                onChange={(e)=> setQuestionType(e.target.value)}
                                value={questionType ?? 'question'}
                                style={{ width: 'calc(100% - 7.25rem)', maxWidth: '45rem' }}
                            >
                                <option hidden value=''>Pilih kategori</option>
                                {questionTypeRef.map(option => (
                                    <option value={option.value} key={`option_${option.value}`}>{option.label}</option>
                                ))}
                            </Form.Select>
                            <Button
                                type='submit'
                                style={{ width: 'auto' }}
                                className='d-flex gap-1 align-items-center text-light'
                            >
                                <span className='material-symbols-outlined'>add</span> Add New
                            </Button>
                        </div>
                    </Form>

                    {isEmptyQuestion &&
                        <i className='d-block mt-2 text-danger'>
                            * Please add with some types question that will be create on the servolution form for general services
                        </i>
                    }
                    
                    <Form
                        noValidate
                        validated={validated}
                        className='form_event_question'
                        onSubmit={onSubmit}
                    >
                        <DndContainer className='form_question_container'>
                            {dataQuestion.map((question, index) => {
                                const QuestionComponent = formQuestionComponents[question.type];
                                return (
                                    <DndItem
                                        key={`form_question_item_${question.id}`}
                                        index={index}
                                        id={question.id}
                                        className='form_question_item'
                                        moveCard={onMoveCard}
                                    >
                                        <button className='form_question_item_draggable'>
                                            <span className="material-symbols-outlined">
                                                drag_indicator
                                            </span>
                                        </button>
                                        <QuestionComponent
                                            required
                                            key={`form_question_${question.type}_${index}`}
                                            className='form_question_item_input'
                                            onChangeQuestion={text => onChangeQuestion(index, text)}
                                            onChangeFileProperty={(key, value) => onChangeFileProperty(index, key, value)}
                                            onChangeOption={(key, value) => onChangeOption(index, key, value)}
                                            onDuplicate={() => onDuplicateQuestion(index)}
                                            onDelete={() => onDeleteQuestion(index)}
                                            {...question}
                                        />
                                    </DndItem>
                                );
                            })}
                        </DndContainer>

                        <hr className='my-0' />

                        <div className='w-100 d-flex justify-content-end gap-1 mt-3'>
                            <Button variant='secondary' onClick={onPrevPage}>Previous</Button>
                            <Button type='submit' className='d-flex align-items-center gap-1 text-light'>
                                Next <span className='material-symbols-outlined'>keyboard_arrow_right</span>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </DndProvider>
    )
}
