import React from 'react';
import { Link } from 'react-router-dom';
import { useSetting } from 'datas/setting/store';
import './style.scss';

const ToggleHeader = () => {
    const { openSetting, setOpenSetting } = useSetting();
    return (
        <div className='header_toggle ps-3'>
            <button
                className={`header_toggle_button ${openSetting && 'open'}`}
                onClick={() => setOpenSetting()}
            >
                <span className="material-symbols-outlined">menu</span>
            </button>
        </div>
    )
}

export const Header = () => (
    <header className='header'>
        <div className='header_item'>
            <Link to='/'>
                <img
                    src='/logo.png'
                    alt='Servolution Management System'
                    className='header_icon ms-2'
                />
            </Link>
        </div>
        <div className='header_item justify-content-end'>
            <ToggleHeader />
        </div>
    </header>
)