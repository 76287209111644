import React, { useMemo } from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import Header from 'components/organisms/Header';
import SidebarMenu from 'components/organisms/Sidebar/SidebarMenu';
import SidebarSetting from 'components/organisms/Sidebar/SidebarSetting';
import routes from 'route';
const packages = require('../../package.json');

const Layout = (props: React.PropsWithChildren) => {
    const sidebarMenuIds = ['users', 'roles', 'ministries', 'divisions', 'events', 'babtism', 'volunteer-lists'];

    const sidebarMenus = useMemo(() => {
        return routes.filter(({ id }) => sidebarMenuIds.includes(id));
    }, [])

    return (
        <section className='layout_base'>
            <Header />

            <main className='layout_base_content_container'>
                <article className='layout_base_content'>
                    <SidebarMenu items={sidebarMenus} />
                    <section className='layout_base_content_menu'>
                        <div>
                            <Breadcrumb />
                            {props.children}
                        </div>
                    </section>
                </article>

                <SidebarSetting />
            </main>

            <span className='version_tag'>{packages.version}</span>
        </section>
    )
}

export default Layout;