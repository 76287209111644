import { questionImageCategoryRef, questionImageSizeRef, questionImageTypeRef } from "constants/event";
import React, { useEffect, useId, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';

interface FormQuestionFileProps {
    question: string;
    required?: boolean;
    className?: string;
    fileCategory?: string;
    fileType?: string;
    fileSize?: string;
    onChangeQuestion: (question: string) => void;
    onChangeFileProperty: (key: 'fileCategory' | 'fileType' | 'fileSize', value: string) => void;
    onDuplicate: (e: React.MouseEvent<HTMLElement>) => void;
    onDelete: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FormQuestionFile = (props: FormQuestionFileProps) => {
    const id = useId();
    const editorRef = useRef<HTMLDivElement>(null);

    const applyFormat = (command: string) => {
        document.execCommand(command, false, '');
    };

    const handleButtonClick = (command: string) => {
        if (editorRef.current) {
            editorRef.current.focus();
            applyFormat(command);
        }
    };

    useEffect(() => {
        if (props.question && editorRef.current) {
            editorRef.current.textContent = props.question;
        }
    }, [])

    return (
        <Form.Group className={`form_question form_question_file ${props.className}`}>
            {props.required && (
                <>
                    <Form.Control
                        required
                        value={props.question}
                        onChange={() => {}}
                        className="form_question_validate"
                    />

                    <Form.Control.Feedback type='invalid'>
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </>
            )}

            <div
                id={`form-question-file-${id}`}
                ref={editorRef}
                contentEditable="true"
                onInput={(e) => props.onChangeQuestion(e.currentTarget.textContent ?? '')}
                className="form-control tw-border tw-border-[var(--border)] p-3 min-h-[100px] rounded"
            />
            
            <div className='d-flex gap-3 mt-2 ms-2'>
                <button type="button" onClick={() => handleButtonClick('bold')}>
                    <b className='tw-font-bolder'>B</b>
                </button>
                <button type="button" onClick={() => handleButtonClick('italic')}>
                    <i className='tw-italic'>I</i>
                </button>
                <button type="button" onClick={() => handleButtonClick('underline')}>
                    <u className='tw-underline'>U</u>
                </button>
            </div>

            <div className="d-flex gap-1 mt-3">
                <Form.Select
                    required
                    aria-label="Tyle File"
                    onChange={(e) => props.onChangeFileProperty('fileCategory', e.target.value)}
                    value={props.fileCategory}
                >
                    <option hidden value=''>Pilih kategori</option>
                    {questionImageCategoryRef.map(cat => 
                        <option value={cat.value} key={`category-option-${cat.value}`}>
                            {cat.label}
                        </option>
                    )}
                </Form.Select>
                <Form.Select
                    required
                    aria-label="type Image"
                    onChange={(e) => props.onChangeFileProperty('fileType', e.target.value)}
                    value={props.fileType}
                >
                    <option hidden value=''>Pilih type</option>
                    {questionImageTypeRef.map(cat => 
                        <option value={cat.value} key={`type-option-${cat.value}`}>
                            {cat.label}
                        </option>
                    )}
                </Form.Select>
                <Form.Select
                    required
                    aria-label="Maksimum File"
                    onChange={(e) => props.onChangeFileProperty('fileSize', e.target.value)}
                    value={props.fileSize}
                >
                    <option hidden value=''>Ukuran maksimum file</option>
                    {questionImageSizeRef.map(cat => 
                        <option value={cat.value} key={`size-option-${cat.value}`}>
                            {cat.label}
                        </option>
                    )}
                </Form.Select>
            </div>

            <div className='d-flex gap-1 mt-3'>
                <Button className='text-light' onClick={props.onDuplicate}>
                    Duplicate
                </Button>
                <Button 
                    variant="outline-danger"
                    color="danger"
                    onClick={props.onDelete}
                >
                    Delete
                </Button>
            </div>
        </Form.Group>
    )
}
