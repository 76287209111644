import { fetchData } from "utils/fetch"
import { MinistrySetupDetail, MinistrySetupDetailPayload } from "./type";

export const postAttachment = async (data: FormData): Promise<string> => {
    try {
        const response = await fetchData<any>(`/servolution/event/attachment`, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            method: 'POST',
            data,
        });
        if (response?.data?.blobName) {
            return response.data.blobName;
        }

        throw response;
    } catch (err) {
        throw err;
    }
}

export const patchMinistrySetup = async (data: MinistrySetupDetailPayload): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/event/details`, {
            method: 'PATCH',
            data,
        });
        if (response?.data) {
            return response.data;
        }

        throw response;
    } catch (err) {
        throw err;
    }
}