import React from 'react';
import { useDrop } from 'react-dnd';

type DndContainerProps = {
    className?: string;
} & React.PropsWithChildren;

export const DndContainer = (props: DndContainerProps) => {
    const [, drop] = useDrop(() => ({ accept: 'card' }));

    return (
        <div className={props.className}>
            <div ref={drop}>
                {props.children}
            </div>
        </div>
    )
}