import React from 'react';
import './style.scss';

type Step<T> = {
    id: T;
    label: string;
    icon: string;
}

type StepperProps<T> = {
    activeKey: T;
    tabs: Step<T>[];
}

export const Stepper = <T extends any>(props: StepperProps<T>) => {
    return (
        <div className='stepper'>
            {props.tabs.map(tab => (
                <div key={`stepper_tab_${tab.id}`} className={`stepper_item ${props.activeKey === tab.id ? 'active' : ''}`}>
                    <div className='stepper_item_icon'>
                        <span className="material-symbols-outlined">{tab.icon}</span>
                    </div>
                    <span className='stepper_item_label'>{tab.label}</span>
                </div>
            ))}
        </div>
    )
}