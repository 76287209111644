import { MinistryOptionType } from "constants/event";

export enum TabKey {
    'schedule',
    'requirement',
    'form',
    'ministry_types',
    'ministry_options'
}

export enum EventStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export enum FormQuestionType {
    QUESTION = 'QUESTION',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    FILE = 'FILE'
}

export type Question = {
    id: number;
    type: FormQuestionType;
    question: string;
    choices?: string[];
    fileCategory?: string;
    fileType?: string;
    fileSize?: string;
}

export type Event = {
    id: string;
    church_id: string;
    church_name: string;
    open_date: string;
    close_date: string;
    maximum_approval_date: string;
    is_assessment_broadcasted: boolean;
    status: string;
}

// list of events (overview)
export type EventSchedule = {
    churchId: string;
    churchName: string;
    closeDate: string;
    openDate: string;
    maximumApprovalDate: string;
}

export type EventRequirement = {
    nijRequirement: boolean;
    msjRequirement: number;
    baptismRequirement: boolean;
    isAssessmentBroadcasted: boolean;
    termsAndConditions: string;
}

export type EventMinistryType = {
    servolutionTypeId: number;
    firstOptionSecondOptionType?: MinistryOptionType;
}

export type EventDetail = {
    id: string;
    churchId: string;
    churchName: string;
    
    servolutionTypeId: number;
    firstOptionMinistries: string[];
    secondOptionMinistries: string[];
    termsAndConditions: string;
    firstOptionSecondOptionType: number;
    eventsQuestionsForm: Question[];

    status: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
} & EventSchedule & EventRequirement & EventMinistryType;

export type Churches = {
    id: number;
    couchdbId: string;
    type: string;
    name: string;
    status: string;
    city: {
        name: string;
        city_id: number;
        geometry?: {
            lat: number;
            lng: number;
        };
        gmtOffset: number;
        timezone_abbreviation: string;
    };
    services: {
        name: string;
        alias?: string;
        service_id: string;
        servicecode: string;
    }[] | null;
    country: {
        name: string;
        country_id: string;
    };
    cityCode: string;
    churchcode: string;
    oldId: number | null;
    isDeleted: boolean;
    updatedAt: string;
    createdAt: string;
    parentCouchdbId: string;
    code: string;
    regionId: number;
    canFollowup: boolean;
}

export type Division = {
    id: string;
    name: string;
    description: string;
    serviceType: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export type Ministry = {
    id: number;
    churchId: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    divisionsId: string;
    ministryTypeId: number;
    divisions: Division & {
        ministryId: string;
        ministryTypeId: number;
        ministries: {
            id: string;
            name: string;
            description: string;
            createdAt: string;
            updatedAt: string;
            deletedAt: string;
            departmentId: string;
            ministryTypeId: number;
        };
    };
    ministryType: MinistryType;
}

export type MinistryType = {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export type MinistryOptions = {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    departmentId: string;
    ministryTypeId: number;
    divisions?: Division[];
}