import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import DataTable, { createTheme, TableColumn, TableStyles, TableProps as Prop } from 'react-data-table-component';

export type TableProps<T> = {
    headerTitle?: string;
    searchBy?: string[];
    searchPlaceholder?: string;
    actionCreateLabel?: string;
    actionCreate?: () => void;
    actionHandler?: (row: T, action: 'edit' | 'delete') => void;
}

export const Table = <T,>(props: Prop<T> & TableProps<T>) => {
    const [filterKeyword, setFilterKeyword] = useState('');

    createTheme('default', {
        background: {
            default: 'var(--background)'
        },
        text: {
            primary: 'var(--primary)',
            secondary: 'var(--text)',
            disabled: 'var(--text-fade)'
        },
        highlightOnHover: {
            default: 'var(--backdrop)'
        },
        selected: {
            default: 'var(--backdrop)'
        },
        divider: {
            default: 'var(--border)'
        },
        button: {
            default: 'var(--text)',
            disabled: 'var(--text-fade)',
		},
    });

    // Internally, customStyles will deep merges your customStyles with the default styling.
    const customStyles: TableStyles = {
        tableWrapper: {
            style: {
                minHeight: '16.625rem',
            }
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'var(--backdrop)',
            }
        },
        headRow: {
            style: {
                backgroundColor: 'var(--backdrop)',
            }
        }
    };

    const onChangeFilterKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterKeyword(e.target.value);
    }

    const actionHandlerColumn: TableColumn<T> = { name: '', cell: row => (
        <Dropdown className='table_options'>
            <Dropdown.Toggle className='table_options_toggle'>
                <span className='material-icons-outlined'>more_vert</span>
            </Dropdown.Toggle>
            
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => props.actionHandler!(row, 'edit')}>
                    <span className='material-icons-outlined'>mode_edit</span> Edit
                </Dropdown.Item>
                
                <Dropdown.Item onClick={() => props.actionHandler!(row, 'delete')}>
                    <span className='material-icons-outlined'>delete_forever</span> Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    ),
    style: { display: 'flex', justifyContent: 'flex-end' }, allowOverflow: true }

    const columns = useMemo<TableColumn<T>[]>(() => {
        const columns = [...props.columns];
        if (props.actionHandler) {
            columns.push(actionHandlerColumn);
        }
        return columns;
    }, [props.actionHandler])

    const data = useMemo<T[]>(() => {
        if (!props.searchBy?.length || !filterKeyword?.length || !props.data?.length) {
            return props.data;
        }

        const keyword = filterKeyword.toLowerCase();

        return props.data?.filter((item) => {
            let status = false;
            props.searchBy?.forEach((key) => {
                // @ts-ignore ~ ignore dynamic object types
                if (item[key] && item[key].toLowerCase().includes(keyword)) {
                    status = true;
                };
            });
            return status;
        })
    }, [props.data, filterKeyword])

    return (
        <React.Fragment>
            <div className='table_header'>
                {props.searchBy?.length ? (
                    <div className='table_header_filter pb-3'>
                        <div className='table_header_title'>
                            <h2 className='fw-bold'>{props.headerTitle ?? ''}</h2>
                        </div>

                        <div className='table_header_filter_form'>
                            <div className='form-control-keyword'>
                                <span className="material-icons-outlined">
                                    search
                                </span>
                                <FormControl
                                    value={filterKeyword}
                                    onChange={onChangeFilterKeyword}
                                    placeholder={props.searchPlaceholder ?? 'Search ...'}
                                />
                            </div>
                            {props.actionCreate ? (
                                <Button onClick={props.actionCreate}>
                                    <span className="material-icons-outlined">add</span>
                                    {props.actionCreateLabel || 'Create'}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
            <DataTable
                {...props}
                data={data}
                columns={columns}
                theme='system'
                customStyles={customStyles}
            />
        </React.Fragment>
    )
}