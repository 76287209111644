import React, { useCallback, useEffect, useId, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';

interface FormCheckProps {
    question: string;
    choices?: string[];
    required?: boolean;
    className?: string;
    onChangeQuestion: (question: string) => void;
    onChangeOption: (index: number, value?: string) => void;
    onDuplicate: (e: React.MouseEvent<HTMLElement>) => void;
    onDelete: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FormQuestionCheckbox = (props: FormCheckProps) => {
    const id = useId();
    const editorRef = useRef<HTMLDivElement>(null);

    const applyFormat = (command: string) => {
        document.execCommand(command, false, '');
    };

    const handleButtonClick = (command: string) => {
        if (editorRef.current) {
            editorRef.current.focus();
            applyFormat(command);
        }
    };

    const handleAddOption = useCallback(() => {
        props.onChangeOption(props.choices?.length ?? 1, '');
    }, [props.choices])

    const handleRemoveOption = useCallback((index: number) => {
        props.onChangeOption(index);
    }, [props.choices])

    useEffect(() => {
        if (props.question && editorRef.current) {
            editorRef.current.textContent = props.question;
        }
    }, [])

    return (
        <div className={`form_question form_question_checkbox ${props.className}`}>
            {props.required && (
                <>
                    <Form.Control
                        required
                        value={props.question}
                        onChange={() => {}}
                        className="form_question_validate"
                    />

                    <Form.Control.Feedback type='invalid'>
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </>
            )}

            {/* Question Field */}
            <div
                id={`form-question-checkbox-${id}`}
                ref={editorRef}
                contentEditable="true"
                onInput={(e) => props.onChangeQuestion(e.currentTarget.textContent ?? '')}
                className="form-control tw-border tw-border-[var(--border)] p-3 min-h-[100px] rounded"
            />

            <div className='d-flex gap-3 mt-2 ms-2'>
                <button type="button" onClick={() => handleButtonClick('bold')}>
                    <b className='tw-font-bolder'>B</b>
                </button>
                <button type="button" onClick={() => handleButtonClick('italic')}>
                    <i className='tw-italic'>I</i>
                </button>
                <button type="button" onClick={() => handleButtonClick('underline')}>
                    <u className='tw-underline'>U</u>
                </button>
            </div>

            {/* Option Fields */}
            {props.choices?.map((option, index) => (
                <InputGroup className="mt-3" key={`form_question_checkbox_item_${id}${index}`}>
                    <InputGroup.Checkbox aria-label='Radio button for following text input' disabled/>
                    <Form.Control
                        required
                        value={option}
                        onChange={(e) => props.onChangeOption(index, e.target.value)}
                    />
                    <button
                        className="form_delete_toggle"
                        onClick={() => handleRemoveOption(index)}
                        title='delete option'
                    >
                        <span className='material-symbols-outlined'>delete</span>
                    </button>
                </InputGroup>
            ))}

            <InputGroup className='mt-3' style={{ paddingLeft: '2.4rem'}}>
                <Button
                    className='d-flex align-items-center gap-1 text-light'
                    onClick={handleAddOption}
                >
                    <span className='material-symbols-outlined'>add</span> Add Option
                </Button>
            </InputGroup>
            
            <div className='d-flex gap-1 mt-3'>
                <Button className='text-light' onClick={props.onDuplicate}>
                    Duplicate
                </Button>
                <Button 
                    variant="outline-danger"
                    color="danger"
                    onClick={props.onDelete}
                >
                    Delete
                </Button>
            </div>
        </div>
    )
}
