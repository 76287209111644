import { create } from 'zustand';
import type { SettingState } from './type';

export const useSetting = create<SettingState>(
    set => ({
        openSetting: false,
        setOpenSetting: open => set((state) => ({
            openSetting: open ?? !state.openSetting
        }))
    })
)