import React from 'react';
import { toast } from 'react-toastify';

type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

export const showToast = (
    type: TypeOptions,
    message: string, 
    options?: { components?: any }
) => {
    // @ts-ignore ~ not fixed toast types
    toast[type](options?.components ?? <div>{message}</div>, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            backgroundColor: 'var(--background)',
            border: '1px solid var(--backdrop)'
        },
        ...(options ?? {})
    })
}