import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEvent } from 'datas/event/store';
import { Churches, EventSchedule, TabKey } from 'datas/event/type';
import { getRefChurches } from 'datas/event/client';
import { churchRef } from 'constants/event';
import './style.scss';

type ErrorValidate = {[key in keyof EventSchedule]: string};

export const FormEventSchedule = () => {
    const navigate = useNavigate();
    const { setActiveTab, dataSchedule, setDataScheduleByKey } = useEvent();
    
    const [validated, setValidated] = useState(false);
    const [errorValidate, setErrorValidate] = useState<ErrorValidate>({} as ErrorValidate);
    const [dataRefChurches, setDataRefChurches] = useState<Churches[]>([]);

    const onNextPage = () => {
        setActiveTab(TabKey.requirement);
    }

    const handleValidateScheduleDate = (openDate: string, closeDate: string, maximumApprovalDate: string) => {
        const open = new Date(openDate);
        const close = new Date(closeDate);
        const maxApproval = new Date(maximumApprovalDate);

        const newErrorValidate = { ...errorValidate };
    
        if (open >= close) {
            newErrorValidate.openDate = 'Open date must be less than the close date.';
        } else if (open >= maxApproval) {
            newErrorValidate.openDate = 'Open date must be less than the maximum approval date.';
        }
    
        if (close <= open) {
            newErrorValidate.closeDate = 'Close date must be greater than the open date.';
        } else if (close >= maxApproval) {
            newErrorValidate.closeDate = 'Close date must be less than the maximum approval date.';
        }
    
        if (maxApproval <= open) {
            newErrorValidate.maximumApprovalDate = 'Maximum approval date must be greater than the open date.';
        } else if (maxApproval <= close) {
            newErrorValidate.maximumApprovalDate = 'Maximum approval date must be greater than the close date.';
        }
        
        return newErrorValidate;
    }

    const onLoadDataRefChurches = async () => {
        const churches = churchRef.join(',');
        const dataRef = await getRefChurches({ churches });
        if (dataRef?.length) {
            setDataRefChurches(dataRef);
        }
    }

    const onChange = (key: keyof EventSchedule, value: string) => {
        setDataScheduleByKey(key, value);

        if (key === 'churchId') {
            const churchName = dataRefChurches.find(ref => ref.couchdbId === value);
            setTimeout(() => setDataScheduleByKey('churchName', churchName?.name ?? ''));
        } else {
            if (Object.keys(errorValidate)?.length) {
                setErrorValidate({} as ErrorValidate);
            }
        }
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                setValidated(true);
                return;
            } else {
                const validateScheduleDate = handleValidateScheduleDate(
                    dataSchedule.openDate, 
                    dataSchedule.closeDate, 
                    dataSchedule.maximumApprovalDate
                );

                if (Object.keys(validateScheduleDate).length) {
                    setErrorValidate(validateScheduleDate);
                    return;
                }
            }

            onNextPage();
        } catch (err: any) {

        } finally {
            
        }
    }

    const onCancel = () => {
        navigate('/event');
    }

    useEffect(() => {
        onLoadDataRefChurches();
        return () => {
            setValidated(false);
            setDataRefChurches([]);
            setErrorValidate({} as ErrorValidate);
        }
    }, [])

    return (
        <Form
            noValidate
            validated={validated}
            className='form_event_schedule'
            onSubmit={onSubmit}
        >
            <div className='form_event_schedule_header'>
                <h2>Detail Schedule</h2>
            </div>
            <div className='form_event_schedule_content'>
                <Form.Group className='form_group'>
                    <Form.Label>RUPS (automatic based on system)</Form.Label>
                    <Form.Select
                        required
                        aria-label="MSJ Required?"
                        onChange={e => onChange('churchId', e.target.value)}
                        value={dataSchedule.churchId !== undefined
                                ? dataSchedule.churchId.toString()
                                : ''
                            }
                    >
                        <option hidden value=''>Pilih kategori</option>
                        {dataRefChurches.map(option => (
                            <option value={option.couchdbId} key={`option_${option.couchdbId}`}>{option.name}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='form_group'>
                    <Form.Label>Close Date</Form.Label>
                    <Form.Control
                        required
                        type='date'
                        value={dataSchedule?.closeDate ?? ''}
                        onChange={e => onChange('closeDate', e.target.value)}
                        isInvalid={!!errorValidate.closeDate}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errorValidate.closeDate ?? 'Pertanyaan wajib diisi'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='form_group'>
                    <Form.Label>Open Date</Form.Label>
                    <Form.Control
                        required
                        type='date'
                        value={dataSchedule?.openDate ?? ''}
                        onChange={e => onChange('openDate', e.target.value)}
                        isInvalid={!!errorValidate.openDate}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errorValidate.openDate ?? 'Pertanyaan wajib diisi'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='form_group'>
                    <Form.Label>Approval Date</Form.Label>
                    <Form.Control
                        required
                        type='date'
                        value={dataSchedule?.maximumApprovalDate ?? ''}
                        onChange={e => onChange('maximumApprovalDate', e.target.value)}
                        isInvalid={!!errorValidate.maximumApprovalDate}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errorValidate.maximumApprovalDate ?? 'Pertanyaan wajib diisi'}
                    </Form.Control.Feedback>
                </Form.Group>

                <hr className='w-100 my-0' />
            
                <div className='w-100 d-flex justify-content-end gap-1'>
                    <Button variant='secondary' onClick={onCancel}>Cancel</Button>
                    <Button type='submit' className='d-flex align-items-center gap-1 text-light'>
                        Next <span className='material-symbols-outlined'>keyboard_arrow_right</span>
                    </Button>
                </div>
            </div>
        </Form>
    )
}