import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import AuthProvider from 'utils/auth-provider';
import Toast from 'components/atoms/Toast';
import LayoutProvider from 'utils/layout-provider';
import routes, { componentLists } from 'route';
import 'tailwindcss/tailwind.css';
import 'styles/main.scss';
import AuthProvider from 'utils/auth-provider';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <AuthProvider>
      <LayoutProvider>
          <Routes>
            {routes.map(route => {
              const Component = componentLists[route.id as keyof typeof componentLists];
              return (
                <Route
                  key={`route_${route.id}`}
                  element={Component ? <Component /> : <></>}
                  path={route.pathname}
                />
              )
            })}
            <Route element={<Navigate to='/events' />} path='*' />
          </Routes>
      </LayoutProvider>
    </AuthProvider>
    <Toast />
  </BrowserRouter>
);
