import { Question } from "datas/event/type";
import type { MinistrySetupDetail, MinistrySetupDetailSchedule } from "./type"
import { create } from "zustand";

type MinistrySetupStore = {
    payload?: MinistrySetupDetail;
    setPayload: (payload: MinistrySetupDetail | ((prev: MinistrySetupDetail) => MinistrySetupDetail)) => void;

    dataQuestion: Question[];
    setDataQuestion: (dataQuestion: Question[] | ((prevDataQuestion: Question[]) => Question[])) => void;

    dataSchedule: MinistrySetupDetailSchedule[];
    setDataSchedule: (dataSchedule: MinistrySetupDetailSchedule[] | ((prevDataSchedule: MinistrySetupDetailSchedule[]) => MinistrySetupDetailSchedule[])) => void;
}

export const useMinistrySetup = create<MinistrySetupStore>(set => ({
    payload: undefined,
    setPayload: data => set(state => ({
        payload: typeof data === 'function' ? data(state.payload ?? {} as MinistrySetupDetail) : data,
    })),

    dataQuestion: [],
    setDataQuestion: data => set(state => ({
        dataQuestion: typeof data === 'function' ? data(state.dataQuestion) : data,
    })),

    dataSchedule: [],
    setDataSchedule: data => set(state => ({
        dataSchedule: typeof data === 'function' ? data(state.dataSchedule) : data,
    }))
}))