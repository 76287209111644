import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Item = {
    id: string;
    icon: string;
    label: string;
}

type SidebarItemProps = {
    item: Item;
}

export const SidebarItem = React.memo<SidebarItemProps>(props => {
    const navigate = useNavigate();
    const location = useLocation();

    const onClickItem = () => {
        navigate(`/${props.item.id}`);
    }

    const isActive = useMemo(() => {
        return location.pathname.includes(props.item.id);
    }, [location.pathname, props.item.id])

    return (
        <button
            className={`sidebar_menu_item ${isActive ? 'active' : ''}`}
            onClick={onClickItem}
            disabled={isActive}
        >
            <div className='sidebar_menu_item_icon'>
                <span className="material-symbols-outlined">
                    {props.item.icon}
                </span>
            </div>
            <span className='sidebar_menu_item_label'>
                {props.item.label} 
            </span>
        </button>
    )
});