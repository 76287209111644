import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useEvent } from 'datas/event/store';

type EventTableActionBarProps = {
    id: string;
    onDuplicateEvent: (id: string) => void;
}

export const EventTableActionBar = (props: EventTableActionBarProps) => {
    const navigate = useNavigate();
    const setIsLoading = useEvent(state => state.setIsLoading);
    const setMessageLoading = useEvent(state => state.setMessageLoading);

    const onEditEvent = () => {
        navigate('/events/edit', {
            state: { eventId: props.id }
        });
    }

    const onMinistrySetup = () => {
        navigate('/events/setup', {
            state: { eventId: props.id }
        });
    }

    return (
        <div className='event_table_action_bar py-3'>
            <Button variant='info' title='Broadcast Event' disabled>
                <span className='material-symbols-outlined'>volume_up</span>
            </Button>
            <Button variant='warning' title='Edit' onClick={onEditEvent}>
                <span className='material-symbols-outlined'>stylus</span>
            </Button>
            <Button title='Ministry Setup' onClick={onMinistrySetup}>
                <span className='material-symbols-outlined text-light'>settings</span>
            </Button>
            <Button variant='success' title='Participants' disabled>
                <span className='material-symbols-outlined'>groups</span>
            </Button>
            <Button title='Duplicate' onClick={() => props.onDuplicateEvent(props.id)}>
                <span className='material-symbols-outlined text-light'>tab_duplicate</span>
            </Button>
            <Button variant='info' title='Statistics' disabled>
                <span className='material-symbols-outlined'>analytics</span>
            </Button>
            <Button variant='danger' title='Delete' disabled>
                <span className='material-symbols-outlined'>delete</span>
            </Button>
        </div>
    )
}