import React, { useEffect } from 'react';
import Stepper from 'components/atoms/Stepper';
import FormEventSchedule from 'components/organisms/Form/FormEventSchedule';
import FormEventRequirement from 'components/organisms/Form/FormEventRequirement';
import FormEventQuestion from 'components/organisms/Form/FormEventQuestion';
import FormMinistryTypes from 'components/organisms/Form/FormMinistryTypes';
import FormMinistryOptions from 'components/organisms/Form/FormMinistryOptions';
import { TabKey } from 'datas/event/type';
import { useEvent } from 'datas/event/store';
import { useLocation } from 'react-router-dom';
import { getEventById } from 'datas/event/client';
import { formatDateyyyyMMdd } from 'utils/format';
import './style.scss';

const tabReferences = [
    { id: TabKey.schedule, label: 'Schedule', icon: 'date_range' },
    { id: TabKey.requirement, label: 'Requirement', icon: 'shield' },
    { id: TabKey.form, label: 'Form', icon: 'list_alt' },
    { id: TabKey.ministry_types, label: 'Ministry Types', icon: 'title' },
    { id: TabKey.ministry_options, label: 'Ministry Options', icon: 'check' }
];

const tabComponentReferences = {
    [TabKey.schedule]: <FormEventSchedule />,
    [TabKey.requirement]: <FormEventRequirement />,
    [TabKey.form]: <FormEventQuestion />,
    [TabKey.ministry_types]: <FormMinistryTypes />,
    [TabKey.ministry_options]: <FormMinistryOptions />
}

export const EventEdit = () => {
    const activeTab = useEvent().activeTab;
    const resetFormData = useEvent().resetFormData;
    const location = useLocation();

    const onLoadDataEventDetail = async (id: string) => {
        try {
            const data = await getEventById(id);
            if (data?.id) {
                const {
                    setDataSchedule,
                    setDataRequirement,
                    setDataQuestion,
                    setDataMinistryType,
                    setDataMinistryFirstOptions,
                    setDataMinistrySecondOptions
                } = useEvent.getState();
                
                // Form Schedule
                const dataSchedule = {
                    churchId: data.churchId,
                    churchName: data.churchName,
                    closeDate: formatDateyyyyMMdd(data.closeDate),
                    openDate: formatDateyyyyMMdd(data.openDate),
                    maximumApprovalDate: formatDateyyyyMMdd(data.maximumApprovalDate),
                }
                setDataSchedule(dataSchedule);

                // Form Requirement
                const dataRequirement = {
                    nijRequirement: data.nijRequirement,
                    msjRequirement: data.msjRequirement,
                    baptismRequirement: data.baptismRequirement,
                    isAssessmentBroadcasted: data.isAssessmentBroadcasted,
                    termsAndConditions: data.termsAndConditions,
                }
                setDataRequirement(dataRequirement);

                // Form Question
                if (data.eventsQuestionsForm?.length) {
                    setDataQuestion(data.eventsQuestionsForm);
                }

                // Form Ministry Type
                if (typeof data.servolutionTypeId === 'number') {
                    setDataMinistryType({
                        servolutionTypeId: data.servolutionTypeId,
                        firstOptionSecondOptionType: data.firstOptionSecondOptionType
                    })
                }
                
                // Form Ministry Option
                if (data.firstOptionMinistries?.length) {
                    setDataMinistryFirstOptions(data.firstOptionMinistries);
                }

                if (data.secondOptionMinistries?.length) {
                    setDataMinistrySecondOptions(data.secondOptionMinistries);
                }
            }
        } catch (err) {
            // noop
        }
    }

    useEffect(() => {
        if (location?.state?.eventId) {
            onLoadDataEventDetail(location.state.eventId);
        }

        return () => {
            resetFormData();
        }
    }, [location.state])

    return (
        <section className='event_edit_container'>
            <div className='event_edit_header'>
                <Stepper<TabKey>
                    tabs={tabReferences}
                    activeKey={activeTab}
                />
            </div>

            {tabComponentReferences[activeTab] as React.ReactNode}
        </section>
    )
}
