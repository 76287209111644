import SidebarItem from 'components/atoms/SidebarItem';
import React, { useMemo, useState } from 'react';
import type { Route } from 'route';
import './style.scss';

type SidebarProps = {
    items: Route[];
}

export const SidebarMenu = React.memo<SidebarProps>(props => {
    const [open, setOpen] = useState(true);

    const toggleSidebar = () => {
        setOpen(prev => !prev);
    }

    const menus = useMemo<{[key: string]: Route[]}>(() => {
        return props.items.reduce((prev, curr) => {
            prev[curr.category] = prev[curr.category] || [];
            prev[curr.category].push(curr);
            return prev;
        }, Object.create(null));
    }, [props.items])

    return (
        <div className={`sidebar_menu ${open ? 'open' : ''}`}>
            <div>
                {Object.keys(menus).map((category: keyof typeof menus) => (
                    <React.Fragment key={`sidebar-menu-${category}`}>
                        <span className='sidebar_menu_group_label d-block'>{category}</span>
                        {menus[category]?.map(item => (
                            <SidebarItem item={item} key={`sidebar-menu-${item.id}`} />
                        ))}
                    </React.Fragment>
                ))} 
            </div>
            <button className='sidebar_menu_toggle' onClick={toggleSidebar}/>
        </div>
    )
})