import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import routeLists from 'route';
import './style.scss';

export const Breadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const onNavigate = useCallback((pathname?: string) => {
        if (!pathname) return;
        
        navigate(pathname, {
            state: location?.state
        });
    }, [location?.state])

    const paths = useMemo(() => {
        const pathname = location.pathname
            .split('/')
            .filter(path => path ? true : false);

        return pathname
            .map((path, index) => {
                const selectedPath = routeLists.find(route => {
                    const currentPathname = [...pathname].splice(0, index + 1).map(path => `/${path}`).join('');
                    const isParentMenu = route.pathname === `/${path}`;
                    const isChildMenu = index > 0 && route.pathname === currentPathname;

                    return isParentMenu || isChildMenu;
                });

                return selectedPath;
            })
            .filter(path => path ? true : false);
    }, [location.pathname])

    return (
        <div className='breadcrumbs'>
            {paths.map(path => (
                <React.Fragment key={`breadcrumbs_item-${path?.id}`}>
                    <span
                        onClick={() => onNavigate(path?.pathname)}
                        className={`breadcrumbs_item ${location.pathname === path?.pathname ? 'active' : ''}`}
                    >
                        {path?.label}
                    </span>
                    <span>/</span>
                </React.Fragment>
            ))}
        </div>
    )
}