import React from 'react';
import { useSetting } from 'datas/setting/store';
import './style.scss';
import { useAuth } from 'datas/auth/store';

export const SidebarSetting = () => {
    const profile = useAuth().profile;
    const open = useSetting(state => state.openSetting);
    return (
        <aside className='sidebar_setting_container'>
            <div className={`sidebar_setting ${open ? 'open' : ''}`}>
                {profile ? (
                    <div className='sidebar_setting_header'>
                        <div className='sidebar_setting_header_photo'>
                            <img src={profile.photo_url} />
                        </div>
                        <div className='sidebar_setting_header_detail'>
                            <span className='fw-bold'>{profile.name}</span>
                            <span className='text-muted'>{profile.church}</span>
                            <span>ADMIN</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </aside>
    )
}
