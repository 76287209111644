import React, { FormEvent, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMinistrySetup } from 'datas/ministry-setup/store';
import { MinistrySetupDetailSchedule } from 'datas/ministry-setup/type';

export const FormMinistrySetupSchedule = () => {
    const { dataSchedule, setDataSchedule } = useMinistrySetup();

    const onChange = (index: number, key: keyof MinistrySetupDetailSchedule, value: any) => {
        setDataSchedule(prev => {
            const newSchedule = [...prev];
            newSchedule.splice(index, 1, {
                ...prev[index],
                [key]: value
            });
            return newSchedule;
        });
    }

    const onCreateNewSchedule = () => {
        setDataSchedule(prev => {
            const newSchedule = [...prev];
            newSchedule.push({} as MinistrySetupDetailSchedule);
            return newSchedule;
        })
    }

    const onDeleteSchedule = (index: number) => {
        setDataSchedule(prev => {
            const newSchedule = [...prev];
            newSchedule.splice(index, 1);
            return newSchedule;
        })
    }

    return (
        <div className='ministry_setup_edit_content'>
            <div className='ministry_setup_edit_content_header'>
                <h4>Schedules & Rooms</h4>
                <i className='text-muted'>Please set your multiple schedules and rooms details here.</i>
            </div>

            {dataSchedule.map((schedule, index) => (
                <React.Fragment key={`ministry_setup_edit_schedule_${index}`}>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <Form.Label>Audition Room</Form.Label>
                            <Form.Control
                                required
                                value={schedule?.audition_room ?? ''}
                                onChange={e => onChange(index, 'audition_room', e.target.value)}
                            />
                        </Col>

                        <Col md={12} className='mb-3'>
                            <Form.Label>Interview Room</Form.Label>
                            <Form.Control
                                required
                                value={schedule?.interview_room ?? ''}
                                onChange={e => onChange(index, 'interview_room', e.target.value)}
                            />
                        </Col>

                        <Col md={6} className='mb-3'>
                            <Form.Label>Audition Date</Form.Label>
                            <Form.Control
                                required
                                type='date'
                                value={schedule?.audition_date ?? ''}
                                onChange={e => onChange(index, 'audition_date', e.target.value)}    
                            />
                        </Col>

                        <Col md={6} className='mb-3'>
                            <Form.Label>Audition Time</Form.Label>
                            <Form.Control
                                required
                                type='time'
                                value={schedule?.audition_time ?? ''}
                                onChange={e => onChange(index, 'audition_time', e.target.value)}    
                            />
                        </Col>

                        <Col md={6} className='mb-3'>
                            <Form.Label>Interview Date</Form.Label>
                            <Form.Control
                                required
                                type='date'
                                value={schedule?.interview_date ?? ''}
                                onChange={e => onChange(index, 'interview_date', e.target.value)}    
                            />
                        </Col>

                        <Col md={6} className='mb-3'>
                            <Form.Label>Interview Time</Form.Label>
                            <Form.Control
                                required
                                type='time'
                                value={schedule?.interview_time ?? ''}
                                onChange={e => onChange(index, 'interview_time', e.target.value)}   
                            />
                        </Col>

                        <Col md={12}>
                            <Button
                                variant="outline-danger"
                                color="danger"
                                className='d-flex align-items-center gap-1'
                                onClick={() => onDeleteSchedule(index)}
                                disabled={!dataSchedule?.length || dataSchedule?.length < 2}
                            >
                                <span className='material-symbols-outlined'>delete</span> Delete
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                </React.Fragment>
            ))}
            
            <Button className='d-flex align-items-center gap-1 text-light' onClick={onCreateNewSchedule}>
                <span className='material-symbols-outlined'>add</span> Add New
            </Button>
        </div>
    )
}