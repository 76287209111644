import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import FormMinistrySetupOverview from 'components/organisms/Form/FormMinistrySetupOverview';
import FormMinistrySetupQuestion from 'components/organisms/Form/FormMinistrySetupQuestion';
import FormMinistrySetupSchedule from 'components/organisms/Form/FormMinistrySetupSchedule';
import { useMinistrySetup } from 'datas/ministry-setup/store';
import { getMinistrySetupById } from 'datas/event/client';
import { MinistrySetupDetail, MinistrySetupDetailPayload, MinistrySetupDetailSchedule } from 'datas/ministry-setup/type';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { patchMinistrySetup, postAttachment } from 'datas/ministry-setup/client';
import { convertToISOFormat } from 'utils/format';
import './style.scss';
import { showToast } from 'utils/toast';

export const EventMinistrySetupEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        payload,
        dataSchedule,
        dataQuestion,
        setPayload,
        setDataQuestion,
        setDataSchedule
    } = useMinistrySetup();
    
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const loadMinistrySetupDetail = async () => {
        const eventId = location.state?.eventId;
        const divisionId = location.state?.divisionId;

        if (!eventId || !divisionId) {
            navigate('/events')
        }

        const data = await getMinistrySetupById({ divisionId, eventId });
        if (data?.length) {
            const overviewField = data[0];
            setPayload(overviewField);
            
            if (overviewField.statement_questions_form?.length) {
                setDataQuestion(overviewField.statement_questions_form);
            }

            const dataSchedule = data.map(schedule => ({
                audition_room: schedule.audition_room,
                audition_date: moment(schedule.audition_schedule).format('yyyy-MM-DD'),
                audition_time: moment(schedule.audition_schedule).format('HH:mm'),
                interview_room: schedule.interview_room,
                interview_date: moment(schedule.interview_schedule).format('yyyy-MM-DD'),
                interview_time: moment(schedule.interview_schedule).format('HH:mm'),
            }));
            setDataSchedule(dataSchedule);
        } else {
            setPayload({} as MinistrySetupDetail);
            setDataQuestion([]);
            setDataSchedule([{} as MinistrySetupDetailSchedule]);
        }
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
    
        try {
            setIsLoading(true);
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                setValidated(true);
                return;
            }
    
            const formData = new FormData();
            if (payload?.attachments instanceof File) {
                formData.append('attachment', payload.attachments);
            } else {
                throw new Error('Invalid attachment format');
            }
            const attachments = await postAttachment(formData);
    
            const dataPayload = dataSchedule.map(schedule => ({
                attachments,
                divisionsId: location.state.divisionId,
                servolutionEventsId: location.state.eventId,
                description: payload?.description,
                auditionRoom: schedule.audition_room,
                auditionSchedule: convertToISOFormat(schedule.audition_date + ' ' + schedule.audition_time),
                interviewRoom: schedule.interview_room,
                interviewSchedule: convertToISOFormat(schedule.interview_date + ' ' + schedule.interview_time),
                statementQuestionsForm: dataQuestion,
            } as MinistrySetupDetailPayload));
    
            await Promise.all(dataPayload.map(data => patchMinistrySetup(data)));
            showToast('success', 'Ministry Setup Successfully Saved');
            navigate('/events/setup', {
                state: location.state
            });
        } catch (err: any) {
            showToast('error', 'Ministry Setup Save Failed. Please try again or contact us for assistance.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadMinistrySetupDetail();
    }, [])
    
    return (
        <DndProvider backend={HTML5Backend}>
            <Form
                noValidate
                validated={validated}
                className='ministry_setup_edit_container'
                onSubmit={onSubmit}
            >
                <FormMinistrySetupOverview />
                <FormMinistrySetupQuestion />
                <FormMinistrySetupSchedule />

                <div className='w-100 d-flex justify-content-end gap-1'>
                    <Button disabled={isLoading} variant='secondary'>Cancel</Button>
                    <Button disabled={isLoading} type='submit' className='d-flex align-items-center gap-2 text-light'>
                        {isLoading && <Spinner size='sm' variant='light' />} Submit
                    </Button>
                </div>
            </Form>
        </DndProvider>
    )
}