import axios from 'axios';
import { useAuth } from './store';
import { Profile } from './type';

const baseUrl = process.env.REACT_APP_API_URL_GMS;

export const getProfile = async (authCode: string): Promise<Profile | null> => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseUrl}/me`,
            headers: {
                Authorization: `Bearer ${authCode}`,
            }
        });
        const data = response?.data?.result;
        if (data?.mygms_id) {
            const { setProfile } = useAuth.getState();
            const profile = {
                person_id: data.mygms_id,
                name: data.name,
                nij: data.nij,
                phone: data.cell_phone?.number,
                email: data.email?.address,
                address: data.address,
                city: data.city?.name,
                country: data.country?.name,
                church: data.church?.name,
                photo_url: data.photo_url,
            };
            setProfile(profile);
            return profile;
        }

        throw response;
    } catch (err) {
        return null;
    }
}