import React, { FormEvent, useCallback, useState } from 'react';
import update from 'immutability-helper';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DndContainer from 'components/atoms/Dnd/Container';
import DndItem from 'components/atoms/Dnd/Item';
import FormQuestionRadio from 'components/molecules/Form/FormQuestion/Radio';
import FormQuestionCheckbox from 'components/molecules/Form/FormQuestion/Checkbox';
import FormQuestionFile from 'components/molecules/Form/FormQuestion/File';
import FormQuestionPlain from 'components/molecules/Form/FormQuestion/Question';
import { useMinistrySetup } from 'datas/ministry-setup/store';
import { FormQuestionType } from 'datas/event/type';
import { questionTypeRef } from 'constants/event';

const formQuestionComponents = {
    [FormQuestionType.RADIO]: FormQuestionRadio,
    [FormQuestionType.CHECKBOX]: FormQuestionCheckbox,
    [FormQuestionType.FILE]: FormQuestionFile,
    [FormQuestionType.QUESTION]: FormQuestionPlain
};

export const FormMinistrySetupQuestion = () => {
    const { dataQuestion, setDataQuestion } = useMinistrySetup();

    const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
    const [questionType, setQuestionType] = useState('');

    const onChangeQuestion = useCallback((index: number, value: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            newQuestion.splice(index, 1, {
                ...prev[index],
                question: value
            });
            return newQuestion;
        });
    }, [dataQuestion])

    const onChangeFileProperty = useCallback((index: number, key: 'fileCategory' | 'fileType' | 'fileSize', value: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            newQuestion.splice(index, 1, {
                ...prev[index],
                [key]: value
            });
            return newQuestion;
        });
    }, [dataQuestion])

    const onChangeOption = useCallback((index: number, optionIndex: number, optionValue?: string) => {
        setDataQuestion(prev => {
            const newQuestion = [...prev];
            const newOptions = [...(newQuestion[index]?.choices ?? [])];

            if (optionValue !== undefined) {
                newOptions[optionIndex] = optionValue;
            } else {
                newOptions.splice(optionIndex, 1);
            }

            newQuestion[index] = {
                ...newQuestion[index],
                choices: newOptions
            };
            
            return newQuestion;
        });
    }, [setDataQuestion]);

    const onMoveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setDataQuestion(prev =>
            update(prev, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prev[dragIndex]],
                ],
            })
        );
    }, []);

    const onCreateNewQuestion = () => {
        if (!questionType) return;

        const newDataQuestion = [...dataQuestion];
        switch (questionType) {
            case FormQuestionType.RADIO:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.RADIO,
                    question: '',
                    choices: ['']
                });
                break;
            case FormQuestionType.CHECKBOX:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.CHECKBOX,
                    question: '',
                    choices: ['']
                });
                break;
            case FormQuestionType.FILE:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.FILE,
                    question: '',
                    fileCategory: '',
                    fileType: '',
                    fileSize: ''
                });
                break;
            default:
                newDataQuestion.push({
                    id: newDataQuestion.length,
                    type: FormQuestionType.QUESTION,
                    question: ''
                });
                break;
        }
        setDataQuestion(newDataQuestion);
        setIsEmptyQuestion(false);
    }

    const onDuplicateQuestion = (index: number) => {
        const newDataQuestion = [...dataQuestion];
        const selectedQuestion = newDataQuestion[index];
        newDataQuestion.splice(index + 1, 0, { ...selectedQuestion, id: newDataQuestion.length });
        setDataQuestion(newDataQuestion);
    }
    
    const onDeleteQuestion = (index: number) => {
        const newDataQuestion = [...dataQuestion];
        newDataQuestion.splice(index, 1);
        setDataQuestion(newDataQuestion);
    }

    return (
        <div className='ministry_setup_edit_content'>
            <div className='ministry_setup_edit_content_header'>
                <h4>Form Question</h4>
                <i className='text-muted'>Please input some of your multiple question for stetament of servolution.</i>
            </div>

            <div className='d-flex gap-1'>
                <Form.Select
                    onChange={(e)=> setQuestionType(e.target.value)}
                    value={questionType ?? 'question'}
                    style={{ width: 'calc(100% - 7.25rem)', maxWidth: '45rem' }}
                >
                    <option hidden value=''>Pilih kategori</option>
                    {questionTypeRef.map(option => (
                        <option value={option.value} key={`option_${option.value}`}>{option.label}</option>
                    ))}
                </Form.Select>
                <Button
                    style={{ width: 'auto' }}
                    className='d-flex gap-1 align-items-center text-light'
                    onClick={onCreateNewQuestion}
                >
                    <span className='material-symbols-outlined'>add</span> Add New
                </Button>
            </div>

            {isEmptyQuestion &&
                <i className='d-block mt-2 text-danger'>
                    * Please add with some types question that will be create on the servolution form for general services
                </i>
            }

            <DndContainer className='ministry_setup_edit_question_container'>
                {dataQuestion.map((question, index) => {
                    const QuestionComponent = formQuestionComponents[question.type];
                    return (
                        <DndItem
                            key={`ministry_setup_edit_question_item_${question.id}`}
                            index={index}
                            id={question.id}
                            className='ministry_setup_edit_question_item'
                            moveCard={onMoveCard}
                        >
                            <button className='ministry_setup_edit_question_item_draggable'>
                                <span className="material-symbols-outlined">
                                    drag_indicator
                                </span>
                            </button>
                            <QuestionComponent
                                required
                                key={`ministry_setup_edit_question_${question.type}_${index}`}
                                className='ministry_setup_edit_question_item_input'
                                onChangeQuestion={text => onChangeQuestion(index, text)}
                                onChangeFileProperty={(key, value) => onChangeFileProperty(index, key, value)}
                                onChangeOption={(key, value) => onChangeOption(index, key, value)}
                                onDuplicate={() => onDuplicateQuestion(index)}
                                onDelete={() => onDeleteQuestion(index)}
                                {...question}
                            />
                        </DndItem>
                    );
                })}
            </DndContainer>
        </div>
    )
}