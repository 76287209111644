import { FormQuestionType } from "datas/event/type";

interface FormItem {
    formType: 'question' | 'upload' | 'multiple' | 'checkbox';
}

export type FormState = FormItem[];

export interface selectOptions {
    label: string;
    value: any;
}

export enum MinistryType {
    'AuditionInterview' = 1,
    'InterviewOnly' = 2,
}

export enum MinistryOptionCategory {
    'FirstOptions' = 1,
    'SecondOptions' = 2,
}

export enum MinistryOptionType {
    'Mandatory' = 1,
    'Optional' = 2,
}

export const churchRef = [
    '421145765f0055368d0b319e7d0382a8', // GMS Tangerang
    '61df7b4dedd41afd950e06851dd7f281', // GMS Kelapa Gading
    '8f4c6a26b91c743ac3168a8f7c00fd99', // GMS Jakarta Barat
    '8f4c6a26b91c743ac3168a8f7c0195fa', // GMS Bandung
]

export const isNIJRequired = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
];

export const isBaptismRequired = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
];

export const isAssessmentBroadcasted = [
    { label: 'Yes', value: '1'  },
    { label: 'No', value: '0' },
];

export const msjRequired = [
    { label: 'MSJ 1', value: '1'  },
    { label: 'MSJ 2', value: '2' },
];

export const questionImageCategoryRef = [
    { label: 'Image', value: 'image' },
];

export const questionImageTypeRef = [
    { label: 'PNG', value: 'png' },
    { label: 'JPG', value: 'jpg' },
    { label: 'JPEG', value: 'jpeg' },
    { label: 'All Types', value: 'all' },
];

export const questionImageSizeRef = [
    { label: '5 Mb', value: '5242880' },
    { label: '10 Mb', value: '10485760' },
];

export const questionTypeRef = [
    { label: 'Question', value: FormQuestionType.QUESTION },
    { label: 'Multiple Choice', value: FormQuestionType.RADIO },
    { label: 'Checkboxes', value: FormQuestionType.CHECKBOX },
    { label: 'File Upload', value: FormQuestionType.FILE }
];

export const ministryCategoryRef = [
    { label: 'Audition & Interview', value: MinistryType.AuditionInterview },
    { label: 'Interview only', value: MinistryType.InterviewOnly },
];

export const ministryOptionCategoryRef = [
    { label: 'First Options', value: MinistryOptionCategory.FirstOptions },
    { label: 'Second Options', value: MinistryOptionCategory.SecondOptions },
]

export const ministryOptionTypeRef = [
    { label: 'Mandatory', value: MinistryOptionType.Mandatory },
    { label: 'Optional', value: MinistryOptionType.Optional },
];

export const defaultEventRequirementTnC = '<h3><strong>Syarat</strong></h3><ol><li>Telah lulus MSJ (My Spiritual Journey) 2.</li><li>Sudah Baptis Selam.</li><li>Sudah menerima Baptisan Roh Kudus.</li><li>Aktif di dalam keanggotaan Connect Group (CG).</li><li>Rekomendasi pemimpin CG (untuk memastikan kesiapan member dalam melayani)&nbsp;.</li></ol><p><br></p><h3><strong>Ketentuan</strong></h3><ol><li>Pendaftaran servolution dilakukan secara online melalui apps GMS Church lalu klik icon&nbsp;Servolution.</li><li>Pendaftaran dibuka mulai&nbsp;{diisi}</li><li>Pastikan GMS tempat dimana anda beribadah sudah sesuai di bagian Edit Profile, GMS (city), yaitu&nbsp;{{diisi}}</li><li>Apabila Anda belum pernah melengkapi data baptisan, maka sebelum memilih pelayanan, Anda diwajibkan melengkapi data baptisan Anda terlebih dahulu.</li><li>Hanya diperbolehkan mengambil satu bidang pelayanan saja.</li><li>Peserta yang mendaftar pada departemen yang memerlukan audisi, wajib memilih opsi kedua pilihan departemen (sebagai bentuk antisipasi apabila tidak lolos pada audisi departemen opsi pertama).</li><li>Data Baptisan Anda akan diverifikasi terlebih dahulu sebelum lanjut ke proses persetujuan pemimpin (bagi Peserta yang belum pernah melengkapi data baptisan).</li><li>Jadwal serta ketentuan audisi dan interview akan diinformasikan melalui aplikasi GMS Church/Email atau melalui departemen yang bersangkutan.</li><li>Nomor Mobile Phone (HP) dan Email yang disertakan harus aktif. Jika ada informasi yang disampaikan lewat HP/Email dan Anda tidak menerimanya dikarenakan kesalahan penulisan atau nomor/email yang tidak aktif, maka segala resiko bukan tanggung jawab kami, contoh: tidak diterima di pelayanan.</li><li>Syarat kelengkapan (dilampirkan via online pada saat mendaftar) seperti&nbsp;Foto Diri.</li><li>Kelulusan akan diumumkan melalui Push Notification di Aplikasi GMS Church dan Email.</li></ol><h3><br></h3>';