import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'datas/auth/store';
import { getProfile } from 'datas/auth/client';
import routeLists from 'route';

const AuthProvider = (props: React.PropsWithChildren) => {
    const [params] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();

    const setupCredentials = async (authCode: string) => {
        // auth.setAuthCode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhjNTBkYTFlYTU1NThiOTQwNGI5MTkwMmY2NjVhNGRmIiwiaWF0IjoxNzE3ODk5NTg5LCJleHAiOjM0MzU4MDI3Nzh9.EaP585hjjeblwuI-l9uJRyYLfbcsZdiTUN7PovbRwZQ');
        auth.setAuthCode(authCode);
        const profile = await getProfile(authCode);

        if (profile?.person_id) {
            const routeProperty = routeLists.find(route => location.pathname.includes(route.pathname));
            if (routeProperty) {
                if (routeProperty.pathname !== location.pathname) {
                    navigate(routeProperty.pathname);
                }
                return;
            }
        }

        navigate('/events');
    }

    useEffect(() => {
        const authCode = params.get('code') ?? auth.authCode;
        if (authCode) {
            setupCredentials(authCode);
        } else {
            window.location.href = `${process.env.REACT_APP_OAUTH_URL}${process.env.REACT_APP_BASE_URL}`;
        }
    }, [])

    return (
        <>
            {auth.profile ? (
                props.children
            ) : (
                <div className='d-flex align-items-center justify-content-center vw-100 vh-100'>
                    <img className='loader' src='/logo.png' style={{ borderRadius: '0.5rem' }} />
                </div>
            )}
        </>
    )
}

export default AuthProvider;