import React from 'react';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.bubble.css'
import 'highlight.js/styles/dark.css'
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import ReactQuill from 'react-quill';
import * as ReactQuillProperties from 'constants/react-quill';
import { useMinistrySetup } from 'datas/ministry-setup/store';
import { MinistrySetupDetail } from 'datas/ministry-setup/type';

hljs.configure({
    languages: ['javascript', 'ruby', 'python', 'rust'],
});

export const FormMinistrySetupOverview = () => {
    const location = useLocation();
    const { payload, setPayload } = useMinistrySetup();

    const onChange = (key: keyof MinistrySetupDetail, value: any) => {
        setPayload((prev = {} as MinistrySetupDetail) => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <div className='ministry_setup_edit_content'>
            <div className='ministry_setup_edit_content_header'>
                <h4 className='fw-bold'>{location.state?.divisionName}</h4>
            </div>

            <Form.Group className='mb-3'>
                <Form.Label>Description</Form.Label>
                <ReactQuill
                    value={payload?.description}
                    onChange={value => onChange('description', value)}
                    modules={ReactQuillProperties.modules}
                    formats={ReactQuillProperties.formats}
                    theme="snow"
                    className='ministry_setup_edit_editor'
                />

                <div className='ministry_setup_edit_validate'>
                    <Form.Control
                        required
                        value={payload?.description}
                        onChange={() => {}}
                    />

                    <Form.Control.Feedback type='invalid'>
                        Pertanyaan wajib diisi
                    </Form.Control.Feedback>
                </div>
            </Form.Group>

            <Form.Group className='mb-3'>
                <Form.Label>Attachment</Form.Label>
                <Form.Control
                    required
                    type='file'
                    onChange={(e) => onChange('attachments', (e.target as HTMLInputElement)?.files?.[0])}
                />
            </Form.Group>
        </div>
    )
}