import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { useEvent } from 'datas/event/store';
import { MinistryOptions } from 'datas/event/type';
import { Button } from 'react-bootstrap';
import './style.scss';
import { MinistryOptionCategory } from 'constants/event';

export const ModalEditDivision = () => {
    const {
        openedDivisionsId,
        dataMinistryFirstOptions,
        dataMinistrySecondOptions,
        dataMinistryOptionRef,
        setOpenedDivisionsId,
        setDataMinistryFirstOptions,
        setDataMinistrySecondOptions
    } = useEvent();

    const [temporaryDataMinistryOptions, setTemporaryDataMinistryOptions] = useState<string[]>([]);

    const onSelectDivision = (ministryId: string) => {
        if (!openedDivisionsId) return;

        setTemporaryDataMinistryOptions(() => {
            const newDataMinistryOptions = [...temporaryDataMinistryOptions];

            const selectedIndex = newDataMinistryOptions?.findIndex(id => id === ministryId);
            if (selectedIndex > -1) {
                newDataMinistryOptions.splice(selectedIndex, 1);
            } else {
                newDataMinistryOptions.push(ministryId);
            }

            return newDataMinistryOptions;
        });
    }

    const onSubmit = () => {
        const setDataMinistryOptions = openedDivisionsId?.optionCategory === MinistryOptionCategory.FirstOptions
                ? setDataMinistryFirstOptions : setDataMinistrySecondOptions;

        setDataMinistryOptions(temporaryDataMinistryOptions);
        onCloseDivisionsModal();
    }
    
    const onCloseDivisionsModal = () => {
        setOpenedDivisionsId(undefined);
    }

    const dataMinistry = useMemo<MinistryOptions | undefined>(() => {
        const selectedDivision = dataMinistryOptionRef.find(ministry => ministry.id === openedDivisionsId?.divisionsId);
        return selectedDivision;
    }, [openedDivisionsId, dataMinistryOptionRef])

    const isOpen = useMemo(() => {
        if (openedDivisionsId) return true;
        return false;
    }, [openedDivisionsId])

    useEffect(() => {
        if (openedDivisionsId) {
            const dataMinistryOptions = openedDivisionsId.optionCategory === MinistryOptionCategory.FirstOptions
                ? dataMinistryFirstOptions : dataMinistrySecondOptions;
            setTemporaryDataMinistryOptions(dataMinistryOptions);
        } else {
            setTemporaryDataMinistryOptions([]);
        }
        
    }, [openedDivisionsId])

    return (
        <Modal show={isOpen} onHide={onCloseDivisionsModal} centered>
            <ModalHeader closeButton>
                {dataMinistry?.name}
            </ModalHeader>
            <ModalBody>
                <i className='text-muted'>Select an item to add it to the division list.</i>
                <div className='modal_edit_division_content'>
                    {openedDivisionsId
                        ? dataMinistry?.divisions?.map((ministry, index) => {
                            const className = temporaryDataMinistryOptions?.includes(ministry.id)
                                ? 'btn btn-primary text-light'
                                : 'btn border-primary text-primary';
                            return (
                                <span
                                    key={`edit-ministry-${ministry.id}${index}`}
                                    onClick={() => onSelectDivision(ministry.id)}
                                    className={className}
                                >
                                    {ministry.name}
                                </span>
                            )
                        }) : null
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type='button' variant='secondary' onClick={onCloseDivisionsModal}>
                    Cancel
                </Button>
                <Button type='button' className='d-flex align-items-center gap-2 text-light' onClick={onSubmit}>
                    <span className="material-symbols-outlined">save</span>Save
                </Button>
            </ModalFooter>
        </Modal>
    )
}