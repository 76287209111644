import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

type DndItemProps = {
    id: number;
    index: number;
    className?: string;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
} & React.PropsWithChildren;

type DragItem = {
    index: number
    id: string
    type: string
}

export const DndItem = (props: DndItemProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >(() => ({
        accept: 'card',
        collect: monitor =>  ({
            handlerId: monitor.getHandlerId(),
        }),
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    }));

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'card',
        item: () => ({
            id: props.id, index: props.index
        }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={props.className}
            data-handler-id={handlerId}
            style={{ opacity }}
        >
            {props.children}
        </div>
    )
    
}